

.nav-bar {
  position: sticky; 
  top: 0;
  display: flex;
  width: 100%;
  background-color: #a9a9a9;
  border: 0;
  border-bottom: 0.2rem;
  border-style: solid;
  border-color: #500319; 
}

.nav-bar-element {
  padding-right: 0.5rem;
}

.home-image {
  height: 3rem;
  padding: 0.2rem;
  margin: auto;
}

.nav-buttons {
  display: flex;
  padding-left: 0.05rem;

  a:link, a:visited {
    text-decoration: none;
    color: black;
  }

  a:hover {
    color: green;
  }
}

