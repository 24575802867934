
.inline {
  display: flex;
}

.inline-child {
  flex: 1;
  flex-basis: content;

  margin: 0rem 0.5rem;
  max-width: 33%;

  .info-block {
    padding: 0.5rem;
  }
}



@media (max-width:1000px)  { 
  /* Disable inline for phones */
  .inline {
    display: block;
    margin: 1rem;
  }
  
  .page-parent {
    width: 100%;
    margin: auto;
  }
  
  .inline-child {
    flex: 0;
    margin: 1rem;
    max-width: 100%;
  }
}

.about-me-block p{
  font-size: large;
}

.info-block {
  background-color: #a9a9a9;

  padding: 1rem;
  border-radius: 1rem;
  align-items: center;
  margin: 1rem auto;
}

.info-fit-width {
  width: fit-content;
}

.title-in-block {
  text-align: center;
  margin: 0.5rem;
}

.scroll-block {
  height: 90%;
  margin: 0.1rem;
  overflow-y: scroll; 
}

.title-block {
  width: fit-content;
  h1 {
    text-align: center;
    margin: 0;
    padding: 0rem 2rem;
  }
}

.profile-block {
  text-align: center;
  p,h6 {

    margin: 0 0.2rem;
  };

  img {
    width: 7rem;
    margin: 0rem auto;
  };
}

.project-header {
  video {
    width: 20rem;
    margin: 0rem auto;
  };
}

.song {
  text-align: center;
  align-items: center;

  h5 {
    margin: 0 auto;
  };
  audio {
    margin-bottom: 1rem;
  }
}


@media (max-width:1000px) { 
  .info-block {
    margin: 1rem auto;
  }

  .title-block h1 {
    text-align: center;
    margin: 0;
    padding: 0rem 1rem;
  }
}