

.project-block-container {
  display: flex;
  flex-wrap: wrap;
  h4 {
    text-align: center;
  }
}

.project-block {
  flex: 0 1 30%;
}

.max-width {
  width: 100%;
}

.flex-container {
  display: flex;
}

.project-image {
  max-width: 80%;
  height: 10rem;
  margin: auto;
}

@media (max-width:1000px)  { 
  /* Disable inline for phones */
  .project-block {
    flex: 0 0 80%;
  }
}